<template>
    <div class="container-fluid cspd-mb important-box cspd-pt cspd-pb">

        <div class="row justify-content-center">
            <div class="col-12 col-md-9">
                <h2>{{ $t('home.campaigns.campaign1Title') }}</h2>
                <p class="mb-4">{{ $t('home.campaigns.campaign1Description') }}</p>
            </div>
        </div><!-- end of row -->

        <div class="row justify-content-center">
            <div class="col-12 col-md-6 col-lg-3 text-center mb-3">
                <a :href="$t('home.campaigns.campaign1Url1')" class="cspd-plain-link" target="_blank">
                    <img class="img-fluid" src="/images/video9.jpg" alt="">
                    <small>{{ $t('home.campaigns.campaign1Text1') }}</small>
                </a>
            </div>
            <div class="col-12 col-md-6 col-lg-3 text-center mb-3">
                <a :href="$t('home.campaigns.campaign1Url2')" class="cspd-plain-link" target="_blank">
                    <img class="img-fluid" src="/images/video10.jpg" alt="">
                    <small>{{ $t('home.campaigns.campaign1Text2') }}</small>
                </a>
            </div>
            <div class="col-12 col-md-6 col-lg-3 text-center">
                <a :href="$t('home.campaigns.campaign1Url3')" class="cspd-plain-link" target="_blank">
                    <img class="img-fluid" src="/images/press-release-campaign-1.jpg" alt="">
                    <small>{{ $t('home.campaigns.campaign1Text3') }}</small>
                </a>
            </div>
        </div><!-- end of row -->

    </div><!-- end of container -->
</template>

<script setup>
import {useI18n} from 'vue-i18n';

const {te} = useI18n();
</script>

<style scoped>
.important-box {
    background-color: rgba(221, 103, 104, 0.08);
}
</style>
<template>
    <div class="container-fluid">
        <div class="row">

            <div class="col-md-6 px-5 d-flex align-items-center cspd-card-red cspd-card-mobile order-2 order-md-1">
                <div>
                    <h1 class="cspd-typo-shadow">{{ $t('home.parents.title') }}</h1>
                    <p class="cspd-lead">{{ $t('home.parents.content1') }}</p>
                    <router-link :to="{ name: 'paediatric-dentistry' }">
                        <button class="btn btn-primary cspd-button-red px-4" type="button">{{ $t('home.parents.button') }}</button>
                    </router-link>
                </div>
            </div><!-- end of column -->

            <div class="col-md-6 g-0 order-1 order-md-2">
                <img class="img-fluid" src="/images/parents.jpg" alt="save a tooth">
            </div>

        </div>
    </div>
</template>


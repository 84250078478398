<template>
    <section class="cspd-mb">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h2>{{ $t('registrations.title2') }}</h2>
                    <p class="mb-4">{{ $t('registrations.content1') }}</p>

                    <p class="cspd-typo-bold cspd-typo-blue">{{ $t('registrations.typeATitle') }}</p>
                    <p>{{ $t('registrations.typeAContent1') }}</p>
                    <p><i class="bi bi-dot"></i> {{ $t('registrations.typeAContent2') }}</p>
                    <p><i class="bi bi-dot"></i> {{ $t('registrations.typeAContent3') }}</p>
                    <p>{{ $t('registrations.typeAContent4') }}</p>

                    <p class="cspd-typo-bold cspd-typo-blue">{{ $t('registrations.typeBTitle') }}</p>
                    <p>{{ $t('registrations.typeBContent1') }}</p>
                    <p><i class="bi bi-dot"></i> {{ $t('registrations.typeBContent2') }}</p>
                    <p><i class="bi bi-dot"></i> {{ $t('registrations.typeBContent3') }}</p>
                    <p>{{ $t('registrations.typeBContent4') }}</p>

                    <p class="cspd-typo-bold cspd-typo-blue">{{ $t('registrations.typeCTitle') }}</p>
                    <p>{{ $t('registrations.typeCContent1') }}</p>
                    <p>{{ $t('registrations.typeCContent2') }}</p>

                    <p class="cspd-typo-bold cspd-typo-blue">{{ $t('registrations.typeDTitle') }}</p>
                    <p>{{ $t('registrations.typeDContent1') }}</p>
                    <p><i class="bi bi-dot"></i> {{ $t('registrations.typeDContent2') }}</p>
                    <p><i class="bi bi-dot"></i> {{ $t('registrations.typeDContent3') }}</p>
                    <p>{{ $t('registrations.typeDContent4') }}</p>
                </div>
            </div>
        </div>
    </section>

    <section class="cspd-pb cspd-pt" style="background-color: #f9f9f9">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h2>{{ $t('registrations.title3') }}</h2>
                    <p><i class="bi bi-dot"></i> {{ $t('registrations.content2') }}</p>
                    <p><i class="bi bi-dot"></i> {{ $t('registrations.content3') }}</p>
                    <p><i class="bi bi-dot"></i> {{ $t('registrations.content4') }}</p>
                    <p><i class="bi bi-dot"></i> {{ $t('registrations.content5') }}</p>
                    <p><i class="bi bi-dot"></i> {{ $t('registrations.content6') }}</p>
                    <p><i class="bi bi-dot"></i> {{ $t('registrations.content7') }}</p>
                </div>
            </div>
        </div>
    </section>

</template>

<script setup>
</script>

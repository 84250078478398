<template>
    <div class="container cspd-mb">
        <div class="row">

            <div class="col-md-6 px-5 d-flex align-items-center cspd-card-blue cspd-card-mobile">
                <div>
                    <h1>{{ $t('members.title') }}</h1>
                    <p><router-link class="cspd-white-link" :to="{name: 'registrations'}"><i class="bi bi-arrow-right"></i> {{ $t('members.link1') }}</router-link></p>
                </div>
            </div>

            <div class="col-md-6 g-0">
                <img class="img-fluid" src="/images/members-title.jpg" alt="doctor dentist">
            </div>

        </div>
    </div>
</template>

<template>
    <section class="cspd-pb cspd-pt" style="background-color: #f9f9f9">
        <div class="container">

            <div class="row">
                <div class="col-md-12">
                    <h2>{{ $t('members.board.title') }}</h2>
                </div>
            </div>

            <div class="row mb-5">
                <div class="col-md-3 col-lg-3">
                    <p class="cspd-lead cspd-typo-blue mb-1">{{ $t('members.board.position1') }}</p>
                    <p>{{ $t('members.board.name1') }}<br><span class="small">{{ $t('members.board.tel1') }}</span></p>
                </div>

                <div class="col-md-3 col-lg-3">
                    <p class="cspd-lead cspd-typo-blue mb-1">{{ $t('members.board.position2') }}</p>
                    <p>{{ $t('members.board.name2') }}<br><span class="small">{{ $t('members.board.tel2') }}</span></p>
                </div>

                <div class="col-md-3 col-lg-3">
                    <p class="cspd-lead cspd-typo-blue mb-1">{{ $t('members.board.position4') }}</p>
                    <p>{{ $t('members.board.name5') }}<br><span class="small">{{ $t('members.board.tel5') }}</span></p>
                </div>

                <div class="col-md-3 col-lg-3">
                    <p class="cspd-lead cspd-typo-blue mb-1">{{ $t('members.board.position3') }}</p>
                    <p>{{ $t('members.board.name3') }}<br><span class="small">{{ $t('members.board.tel3') }}</span></p>
                    <p>{{ $t('members.board.name4') }}<br><span class="small">{{ $t('members.board.tel4') }}</span></p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <p class="mb-4 cspd-typo-bold">{{ $t('members.content') }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-3 col-lg-2 mb-3">
                    <div class="d-grid gap-2">
                        <button @click="toggleButtons('NIC')" class="btn btn-outline-primary cspd-outline-button" :class="{ 'cspd-outline-button-active': nicosiaActive }" type="button">{{ $t('members.nic') }}</button>
                        <button @click="toggleButtons('LIM')" class="btn btn-outline-primary cspd-outline-button" :class="{ 'cspd-outline-button-active': limassolActive }" type="button">{{ $t('members.lim') }}</button>
                        <button @click="toggleButtons('LAR')" class="btn btn-outline-primary cspd-outline-button" :class="{ 'cspd-outline-button-active': larnakaActive }" type="button">{{ $t('members.lar') }}</button>
                        <button @click="toggleButtons('PAF')" class="btn btn-outline-primary cspd-outline-button" :class="{ 'cspd-outline-button-active': pafosActive }" type="button">{{ $t('members.paf') }}</button>
                    </div>
                </div>

                <div class="col-sm-12 col-md-9 col-lg-10 cspd-members-col">

                    <template v-if="nicosiaActive">
                        <div class="row">
                            <div class="col-md-12 col-lg-6">
                                <p><i class="bi bi-dot"></i> {{ $t('members.nicosia.name1') }}<br><span class="small cspd-typo-blue">{{ $t('members.nicosia.tel1') }}</span><br><span class="small" v-html="$t('members.nicosia.info1')"></span></p>
                                <p><i class="bi bi-dot"></i> {{ $t('members.nicosia.name2') }}<br><span class="small cspd-typo-blue">{{ $t('members.nicosia.tel2') }}</span><br><span class="small" v-html="$t('members.nicosia.info2')"></span></p>
                                <p><i class="bi bi-dot"></i> {{ $t('members.nicosia.name3') }}<br><span class="small cspd-typo-blue">{{ $t('members.nicosia.tel3') }}</span><br><span class="small" v-html="$t('members.nicosia.info3')"></span></p>
                                <p><i class="bi bi-dot"></i> {{ $t('members.nicosia.name4') }}<br><span class="small cspd-typo-blue">{{ $t('members.nicosia.tel4') }}</span><br><span class="small" v-html="$t('members.nicosia.info4')"></span></p>
                                <p><i class="bi bi-dot"></i> {{ $t('members.nicosia.name5') }}<br><span class="small cspd-typo-blue">{{ $t('members.nicosia.tel5') }}</span><br><span class="small" v-html="$t('members.nicosia.info5')"></span></p>
                            </div>

                            <div class="col-md-12 col-lg-6">
                                <p><i class="bi bi-dot"></i> {{ $t('members.nicosia.name6') }}<br><span class="small cspd-typo-blue">{{ $t('members.nicosia.tel6') }}</span><br><span class="small" v-html="$t('members.nicosia.info6')"></span></p>
                                <p><i class="bi bi-dot"></i> {{ $t('members.nicosia.name7') }}<br><span class="small cspd-typo-blue">{{ $t('members.nicosia.tel7') }}</span><br><span class="small" v-html="$t('members.nicosia.info7')"></span></p>
                                <p><i class="bi bi-dot"></i> {{ $t('members.nicosia.name8') }}<br><span class="small cspd-typo-blue">{{ $t('members.nicosia.tel8') }}</span><br><span class="small" v-html="$t('members.nicosia.info8')"></span></p>
                                <p><i class="bi bi-dot"></i> {{ $t('members.nicosia.name9') }}<br><span class="small cspd-typo-blue">{{ $t('members.nicosia.tel9') }}</span><br><span class="small" v-html="$t('members.nicosia.info9')"></span></p>
                            </div>

                        </div>
                    </template>

                    <template v-else-if="limassolActive">
                        <div class="row">
                            <div class="col-md-12">
                                <p><i class="bi bi-dot"></i> {{ $t('members.limassol.name1') }}<br><span class="small cspd-typo-blue">{{ $t('members.limassol.tel1') }}</span><br><span class="small" v-html="$t('members.limassol.info1')"></span></p>
                                <p><i class="bi bi-dot"></i> {{ $t('members.limassol.name2') }}<br><span class="small cspd-typo-blue">{{ $t('members.limassol.tel2') }}</span><br><span class="small" v-html="$t('members.limassol.info2')"></span></p>
                                <p><i class="bi bi-dot"></i> {{ $t('members.limassol.name3') }}<br><span class="small cspd-typo-blue">{{ $t('members.limassol.tel3') }}</span><br><span class="small" v-html="$t('members.limassol.info3')"></span></p>
                                <p><i class="bi bi-dot"></i> {{ $t('members.limassol.name4') }}<br><span class="small cspd-typo-blue">{{ $t('members.limassol.tel4') }}</span><br><span class="small" v-html="$t('members.limassol.info4')"></span></p>
                            </div>
                        </div>
                    </template>

                    <template v-else-if="larnakaActive">
                        <div class="row">
                            <div class="col-md-12">
                                <p><i class="bi bi-dot"></i> {{ $t('members.larnaka.name1') }}<br><span class="small cspd-typo-blue">{{ $t('members.larnaka.tel1') }}</span><br><span class="small" v-html="$t('members.larnaka.info1')"></span></p>
                                <p><i class="bi bi-dot"></i> {{ $t('members.larnaka.name2') }}<br><span class="small cspd-typo-blue">{{ $t('members.larnaka.tel2') }}</span><br><span class="small" v-html="$t('members.larnaka.info2')"></span></p>
                                <p><i class="bi bi-dot"></i> {{ $t('members.larnaka.name3') }}<br><span class="small cspd-typo-blue">{{ $t('members.larnaka.tel3') }}</span><br><span class="small" v-html="$t('members.larnaka.info3')"></span></p>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <div class="row">
                            <div class="col-md-12">
                                <p><i class="bi bi-dot"></i> {{ $t('members.pafos.name1') }}<br><span class="small cspd-typo-blue">{{ $t('members.pafos.tel1') }}</span><br><span class="small" v-html="$t('members.pafos.info1')"></span></p>
                                <p><i class="bi bi-dot"></i> {{ $t('members.pafos.name2') }}<br><span class="small cspd-typo-blue">{{ $t('members.pafos.tel2') }}</span><br><span class="small" v-html="$t('members.pafos.info2')"></span></p>
                                <p><i class="bi bi-dot"></i> {{ $t('members.pafos.name3') }}<br><span class="small cspd-typo-blue">{{ $t('members.pafos.tel3') }}</span><br><span class="small" v-html="$t('members.pafos.info3')"></span></p>
                            </div>
                        </div>
                    </template>
                </div>
            </div><!-- end of row -->

        </div><!-- end of container -->
    </section>
</template>
<script setup>
import {ref} from "vue";

const nicosiaActive = ref(true)
const limassolActive = ref(false)
const larnakaActive = ref(false)
const pafosActive = ref(false)

const toggleButtons = (district) => {
    switch(district) {
        case 'NIC':
            nicosiaActive.value = true;
            limassolActive.value = false;
            larnakaActive.value = false;
            pafosActive.value = false;
            break;
        case 'LIM':
            nicosiaActive.value = false;
            limassolActive.value = true;
            larnakaActive.value = false;
            pafosActive.value = false;
            break;
        case 'LAR':
            nicosiaActive.value = false;
            limassolActive.value = false;
            larnakaActive.value = true;
            pafosActive.value = false;
            break;
        case 'PAF':
            nicosiaActive.value = false;
            limassolActive.value = false;
            larnakaActive.value = false;
            pafosActive.value = true;
            break;
    }
}
</script>

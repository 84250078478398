<template>
    <div class="container cspd-mb">
        <div class="row">

            <div class="col-md-6 px-5 d-flex align-items-center cspd-card-yellow cspd-card-mobile">
                <div>
                    <h1 class="cspd-typo-shadow">{{ $t('links.title') }}</h1>
                    <p>{{ $t('links.content') }}</p>
                </div>

            </div>

            <div class="col-md-6 g-0">
                <img class="img-fluid" src="/images/links-title.jpg" alt="girl at the dentist">
            </div>

        </div>
    </div>
</template>

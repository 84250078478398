<template>
    <div class="container cspd-mb">
        <div class="row">

            <div class="col-md-12 px-5">
                <p><i style="color: #07A0E1;" class="bi bi-arrow-right"></i> <a href="http://www.eapd.eu/" class="cspd-plain-link" target="_blank">{{ $t('links.link1') }}</a></p>
                <p><i style="color: #07A0E1;" class="bi bi-arrow-right"></i> <a href="https://www.hspd.gr/" class="cspd-plain-link" target="_blank">{{ $t('links.link2') }}</a></p>
                <p><i style="color: #07A0E1;" class="bi bi-arrow-right"></i> <a href="https://iapdworld.org/" class="cspd-plain-link" target="_blank">{{ $t('links.link3') }}</a></p>
                <p><i style="color: #07A0E1;" class="bi bi-arrow-right"></i> <a href="https://www.aapd.org/" class="cspd-plain-link" target="_blank">{{ $t('links.link4') }}</a></p>
                <p><i style="color: #07A0E1;" class="bi bi-arrow-right"></i> <a href="https://nkdentalcy.com/" class="cspd-plain-link" target="_blank">{{ $t('links.link5') }}</a></p>
                <p><i style="color: #07A0E1;" class="bi bi-arrow-right"></i> <a href="https://child.org.cy/" class="cspd-plain-link" target="_blank">{{ $t('links.link6') }}</a></p>
                <p><i style="color: #07A0E1;" class="bi bi-arrow-right"></i> <a href="https://cos.com.cy/" class="cspd-plain-link" target="_blank">{{ $t('links.link7') }}</a></p>
            </div>

        </div>
    </div>
</template>

<template>
    <div class="container-fluid">
        <div class="row">

            <div class="col-md-12 col-lg-6 g-0">
                <img class="img-fluid" src="/images/intro.jpg" alt="Welcome">
            </div>

            <div class="col-md-12 col-lg-6 px-5 py-4 d-flex align-items-center cspd-card-blue">
                <div class="cspd-intro-container">
                    <h1 class="cspd-typo-shadow" v-html="$t('home.intro.title')"></h1>
                    <p class="cspd-lead">{{ $t('home.intro.content1') }}</p>
                    <p class="cspd-lead">{{ $t('home.intro.content2') }}</p>
                </div>
            </div>

        </div>
    </div>
</template>

<template>
    <footer class="cspd-footer py-4">
        <div class="container">

            <div class="row">
                <div class="col-12 col-md-4 mb-4 col-lg-2">
                    <img src="/images/iapd.jpg" alt="IAPD" width="200" class="img-fluid">
                </div>
                <div class="col-6 col-md-4 col-lg-2">
                    <ul>
                        <li><router-link :to="{name: 'home'}"><i class="bi bi-arrow-right"></i> {{$t('footer.links.link1')}}</router-link></li>
                        <li><router-link :to="{name: 'members'}"><i class="bi bi-arrow-right"></i> {{$t('footer.links.link2')}}</router-link></li>
                        <li><router-link :to="{name: 'paediatric-dentistry'}"><i class="bi bi-arrow-right"></i> {{$t('footer.links.link8')}}</router-link></li>
                        <li><router-link :to="{name: 'articles'}"><i class="bi bi-arrow-right"></i> {{$t('footer.links.link7')}}</router-link></li>
                    </ul>
                </div>
                <div class="col-6 col-md-4 col-lg-3">
                    <ul>
                        <li><router-link :to="{name: 'events'}"><i class="bi bi-arrow-right"></i> {{$t('footer.links.link4')}}</router-link></li>
                        <li><router-link :to="{name: 'links'}"><i class="bi bi-arrow-right"></i> {{$t('footer.links.link5')}}</router-link></li>
                        <li><router-link :to="{name: 'registrations'}"><i class="bi bi-arrow-right"></i> {{$t('footer.links.link3')}}</router-link></li>
                        <li><router-link :to="{name: 'contact'}"><i class="bi bi-arrow-right"></i> {{$t('footer.links.link6')}}</router-link></li>
                    </ul>
                </div>
            </div><!-- end of row -->

            <div class="row mb-3">
                <div class="col text-center">
                    <small class="text-muted">This website does not use any cookies.</small>
                </div>
            </div><!-- end of row -->

        </div>
    </footer>
</template>

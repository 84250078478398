<template>
    <section class="cspd-pb" style="background-color: #f9f9f9">
        <div class="container">

            <div class="row">
                <div class="col-md-12">
                    <h2>{{ $t('home.members.title') }}</h2>
                    <p class="mb-4">{{ $t('home.members.content') }}</p>
                </div>
            </div>


            <div class="row">

                <div class="col-md-3 col-lg-2 mb-3">
                    <div class="d-grid gap-2">
                        <button @click="toggleButtons('NIC')" class="btn btn-outline-primary cspd-outline-button" :class="{ 'cspd-outline-button-active': nicosiaActive }" type="button">{{ $t('home.members.nic') }}</button>
                        <button @click="toggleButtons('LIM')" class="btn btn-outline-primary cspd-outline-button" :class="{ 'cspd-outline-button-active': limassolActive }" type="button">{{ $t('home.members.lim') }}</button>
                        <button @click="toggleButtons('LAR')" class="btn btn-outline-primary cspd-outline-button" :class="{ 'cspd-outline-button-active': larnakaActive }" type="button">{{ $t('home.members.lar') }}</button>
                        <button @click="toggleButtons('PAF')" class="btn btn-outline-primary cspd-outline-button" :class="{ 'cspd-outline-button-active': pafosActive }" type="button">{{ $t('home.members.paf') }}</button>
                    </div>
                </div>

                <div class="col-md-9 col-lg-8 cspd-members-col">

                    <template v-if="nicosiaActive">
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <p>{{ $t('home.members.nicosia.name1') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.nicosia.tel1') }}</span></p>
                                <p>{{ $t('home.members.nicosia.name2') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.nicosia.tel2') }}</span></p>
                                <p>{{ $t('home.members.nicosia.name3') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.nicosia.tel3') }}</span></p>
                            </div>

                            <div class="col-md-4 col-6">
                                <p>{{ $t('home.members.nicosia.name4') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.nicosia.tel4') }}</span></p>
                                <p>{{ $t('home.members.nicosia.name5') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.nicosia.tel5') }}</span></p>
                                <p>{{ $t('home.members.nicosia.name6') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.nicosia.tel6') }}</span></p>
                            </div>

                            <div class="col-md-4 col-6">
                                <p>{{ $t('home.members.nicosia.name7') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.nicosia.tel7') }}</span></p>
                                <p>{{ $t('home.members.nicosia.name8') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.nicosia.tel8') }}</span></p>
                                <p>{{ $t('home.members.nicosia.name9') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.nicosia.tel9') }}</span></p>
                            </div>
                        </div>
                    </template>

                    <template v-else-if="limassolActive">
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <p>{{ $t('home.members.limassol.name1') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.limassol.tel1') }}</span></p>
                                <p>{{ $t('home.members.limassol.name2') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.limassol.tel2') }}</span></p>
                                <p>{{ $t('home.members.limassol.name3') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.limassol.tel3') }}</span></p>
                            </div>

                            <div class="col-md-4 col-6">
                                <p>{{ $t('home.members.limassol.name4') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.limassol.tel4') }}</span></p>
                            </div>
                        </div>
                    </template>

                    <template v-else-if="larnakaActive">
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <p>{{ $t('home.members.larnaka.name1') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.larnaka.tel1') }}</span></p>
                                <p>{{ $t('home.members.larnaka.name2') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.larnaka.tel2') }}</span></p>
                                <p>{{ $t('home.members.larnaka.name3') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.larnaka.tel3') }}</span></p>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <p>{{ $t('home.members.pafos.name1') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.pafos.tel1') }}</span></p>
                                <p>{{ $t('home.members.pafos.name2') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.pafos.tel2') }}</span></p>
                                <p>{{ $t('home.members.pafos.name3') }}<br><span class="small cspd-typo-blue">{{ $t('home.members.pafos.tel3') }}</span></p>
                            </div>
                        </div>
                    </template>
                </div>

            </div>


        </div>
    </section>

</template>

<script setup>
import {ref} from "vue";

const nicosiaActive = ref(true)
const limassolActive = ref(false)
const larnakaActive = ref(false)
const pafosActive = ref(false)

const toggleButtons = (district) => {
    switch(district) {
        case 'NIC':
            nicosiaActive.value = true;
            limassolActive.value = false;
            larnakaActive.value = false;
            pafosActive.value = false;
            break;
        case 'LIM':
            nicosiaActive.value = false;
            limassolActive.value = true;
            larnakaActive.value = false;
            pafosActive.value = false;
            break;
        case 'LAR':
            nicosiaActive.value = false;
            limassolActive.value = false;
            larnakaActive.value = true;
            pafosActive.value = false;
            break;
        case 'PAF':
            nicosiaActive.value = false;
            limassolActive.value = false;
            larnakaActive.value = false;
            pafosActive.value = true;
            break;
    }
}
</script>

<template>
    <section>

        <nav class="navbar navbar-expand-lg px-lg-4 pt-lg-2 pb-lg-5 cspd-nav">
            <div class="container">

                <router-link class="navbar-brand" :to="{name: 'home'}">
                    <div class="d-flex align-items-center">
                        <img src="/images/logo.png" alt="Logo" class="me-3 cspd-logo">
                        <span class="cspd-typo-bold" v-html="$t('header.logo')"></span>
                    </div>
                </router-link>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="bi bi-list"></i>
                </button>

                <div class="collapse navbar-collapse" :class="!visible ? 'collapse' : ''" id="navbarNavDropdown">
                    <ul class="navbar-nav ms-auto">

                        <li @click="visible=!visible" class="nav-item">
                            <router-link class="nav-link" :to="{name: 'home'}">{{ $t('header.nav.home') }}</router-link>
                        </li>

                        <li @click="visible=!visible" class="nav-item">
                            <router-link class="nav-link" :to="{name: 'members'}">{{ $t('header.nav.members') }}</router-link>
                        </li>

                        <li @click="visible=!visible" class="nav-item">
                            <router-link class="nav-link" :to="{name: 'paediatric-dentistry'}">{{ $t('header.nav.parents') }}</router-link>
                        </li>

                        <li @click="visible=!visible" class="nav-item">
                            <router-link class="nav-link" :to="{name: 'articles'}">{{ $t('header.nav.articles') }}</router-link>
                        </li>

                        <li @click="visible=!visible" class="nav-item">
                            <router-link class="nav-link" :to="{name: 'contact'}">{{ $t('header.nav.contact') }}</router-link>
                        </li>

                        <li @click="[visible=!visible, changeActiveLanguage()]" class="nav-item pe-0">
                            <span class="nav-link cspd-languages-link"><i class="bi bi-globe"></i> GR/EN</span>
                        </li>

                    </ul>
                </div>

            </div><!-- end of container -->
        </nav>

    </section>
</template>

<script setup>
import i18n from "@/i18n";
import { ref } from 'vue'

const visible = ref();

const changeActiveLanguage = () => {
    let lang = 'gr';
    if (i18n.global.locale.value === 'gr') {
        lang = 'en';
    }
    i18n.global.locale.value = lang;
};
</script>

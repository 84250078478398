import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue';
import LinksView from '../views/LinksView';
import MembersView from '../views/MembersView';
import RegistrationsView from '../views/RegistrationsView';
import i18n from "@/i18n";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            titleGR: 'Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Cyprus Society of Paediatric Dentistry',
        },
    },
    {
        path: '/links',
        name: 'links',
        component: LinksView,
        meta: {
            titleGR: 'Σύνδεσμοι - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Links - Cyprus Society of Paediatric Dentistry',
        },
    },
    {
        path: '/members',
        name: 'members',
        component: MembersView,
        meta: {
            titleGR: 'Τα μέλη της εταιρείας μας - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Members - Cyprus Society of Paediatric Dentistry',
        },
    },
    {
        path: '/registrations',
        name: 'registrations',
        component: RegistrationsView,
        meta: {
            titleGR: 'Εγγραφές και Ανανεώσεις - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Registrations and Renewals - Cyprus Society of Paediatric Dentistry',
        }
    },
    {
        path: '/events-conferences',
        name: 'events',
        meta: {
            titleGR: 'Συνέδρια και Εκδηλώσεις - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Conferences and Events - Cyprus Society of Paediatric Dentistry',

        },
        component: function () {
            return import('../views/EventsView.vue')
        }
    },
    {
        path: '/articles',
        name: 'articles',
        meta: {
            titleGR: 'Ενδιαφέροντα άρθρα - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Articles - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/ArticlesView.vue')
        }
    },
    {
        path: '/contact',
        name: 'contact',
        meta: {
            titleGR: 'Επικοινωνία - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Contact - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/ContactView.vue')
        }
    },
    {
        path: '/paediatric-dentistry',
        name: 'paediatric-dentistry',
        meta: {
            titleGR: 'Τι είναι η Παιδοδοντριατική; - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'What is Paediatric Dentistry? - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/PaediatricDentistryView.vue')
        }
    },
    {
        path: '/dental-anxiety',
        name: 'dental-anxiety',
        meta: {
            titleGR: 'Οδοντιατρική Φοβία - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'What is dental anxiety/phobia? - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/DentalAnxietyView.vue')
        }
    },
    {
        path: '/first-visit',
        name: 'first-visit',
        meta: {
            titleGR: 'Αρχική Εξέταση - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'When should my baby first visit the dentist? - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/FirstVisitView.vue')
        }
    },
    {
        path: '/patients-with-special-needs',
        name: 'patients-with-special-needs',
        meta: {
            titleGR: 'Παιδιά με ειδικές ανάγκες - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Patients with special needs - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/SpecialNeedsView.vue')
        }
    },
    {
        path: '/pregnant-women',
        name: 'pregnant-women',
        meta: {
            titleGR: 'Προληψη στην έγκυο, το βρέφος και στο νήπιο - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Dental care during pregnancy - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/PregnantWomenView.vue')
        }
    },
    {
        path: '/first-tooth',
        name: 'first-tooth',
        meta: {
            titleGR: 'Το πρώτο του δόντι - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Baby\'s first tooth? - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/FirstToothView.vue')
        }
    },
    {
        path: '/dental-chart',
        name: 'dental-chart',
        meta: {
            titleGR: 'Σειρά ανατολής δοντιών - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/DentalChartView.vue')
        }
    },
    {
        path: '/brushing-teeth',
        name: 'brushing-teeth',
        meta: {
            titleGR: 'Ποτέ ξεκινά το βούρτσισμα; - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'When should I start my baby\'s teeth brushing? - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/BrushingView.vue')
        }
    },
    {
        path: '/first-visit-at-the-dentist',
        name: 'first-visit-at-the-dentist',
        meta: {
            titleGR: 'Πρωτη επίσκεψη του παιδιού στον οδοντίατρο - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/FirstVisitAtTheDentistView.vue')
        }
    },
    {
        path: '/important-baby-teeth',
        name: 'important-baby-teeth',
        meta: {
            titleGR: 'Σημαντικά παιδικά δόντια - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Are baby teeth important? - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/ImportantBabyTeethView.vue')
        }
    },
    {
        path: '/diet',
        name: 'diet',
        meta: {
            titleGR: 'Διατροφή - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'My child\'s diet - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/DietView.vue')
        }
    },
    {
        path: '/soft-drinks',
        name: 'soft-drinks',
        meta: {
            titleGR: 'Αναψυκτικά - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/SoftDrinksView.vue')
        }
    },
    {
        path: '/bad-habits',
        name: 'bad-habits',
        meta: {
            titleGR: 'Κακές στοματικές συνήθειες - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/BadHabitsView.vue')
        }
    },
    {
        path: '/dental-decay',
        name: 'dental-decay',
        meta: {
            titleGR: 'Tερηδόνα - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Dental decay - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/DentalDecayView.vue')
        }
    },
    {
        path: '/gingivitis',
        name: 'gingivitis',
        meta: {
            titleGR: 'Ουλίτιδα - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Dental decay - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/GingivitisView.vue')
        }
    },
    {
        path: '/toothache',
        name: 'toothache',
        meta: {
            titleGR: 'Πονόδοντος - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Toothache - Dental decay - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/ToothacheView.vue')
        }
    },
    {
        path: '/prevention',
        name: 'prevention',
        meta: {
            titleGR: 'Πρόληψη στο Οδοντιατρείο - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/PreventionView.vue')
        }
    },
    {
        path: '/preventative-fillings',
        name: 'preventative-fillings',
        meta: {
            titleGR: 'Προληπτικές Καλύψεις - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/PreventativeFillingsView.vue')
        }
    },
    {
        path: '/oral-hygiene',
        name: 'oral-hygiene',
        meta: {
            titleGR: 'Στοματική υγιεινή στο σπίτι - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/OralHygieneView.vue')
        }
    },
    {
        path: '/therapies',
        name: 'therapies',
        meta: {
            titleGR: 'Θεραπείες - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/TherapiesView.vue')
        }
    },
    {
        path: '/malocclusion',
        name: 'malocclusion',
        meta: {
            titleGR: 'Στραβα δοντάκια - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/MalocclusionView.vue')
        }
    },
    {
        path: '/dental-trauma',
        name: 'dental-trauma',
        meta: {
            titleGR: 'Τραύματα στα δόντια των παιδιών - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'What must i do in a case of injury? - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/DentalTraumaView.vue')
        }
    },
    {
        path: '/enamel-hypoplasia',
        name: 'enamel-hypoplasia',
        meta: {
            titleGR: 'Υποπλασίες και υπενασβεστιώσεις - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'What must i do in a case of injury? - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/EnamelHypoplasiaView.vue')
        }
    },
    {
        path: '/fluoride',
        name: 'fluoride',
        meta: {
            titleGR: 'Φθόριο - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Fluoride against cavities - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/FluorideView.vue')
        }
    },
    {
        path: '/toxicity',
        name: 'toxicity',
        meta: {
            titleGR: 'Τοξικότητα Υλικών για Σφραγίσματα - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/ToxicityView.vue')
        }
    },
    {
        path: '/flossing',
        name: 'flossing',
        meta: {
            titleGR: 'Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Flossing - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/FlossingView.vue')
        }
    },
    {
        path: '/pulpectomy',
        name: 'pulpectomy',
        meta: {
            titleGR: 'Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Why should the dentist remove the nerve from the tooth? - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/PulpectomyView.vue')
        }
    },
    {
        path: '/dental-braces',
        name: 'dental-braces',
        meta: {
            titleGR: 'Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Dental braces - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/BracesView.vue')
        }
    },
    {
        path: '/fillings',
        name: 'fillings',
        meta: {
            titleGR: 'Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Fillings - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/FillingsView.vue')
        }
    },
    {
        path: '/thumb-sucking',
        name: 'thumb-sucking',
        meta: {
            titleGR: 'Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: 'Thumb sucking - Cyprus Society of Paediatric Dentistry',
        },
        component: function () {
            return import('../views/ThumbSuckingView.vue')
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        meta: {
            titleGR: '404 - Παιδοδοντιατρική Εταιρεία Κύπρου',
            titleEN: '404 - Cyprus Society of Paediatric Dentistry',
        },
        component: () => import('../components/PageNotFound.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
    routes
})

router.beforeEach((to, from, next) => {
    let metaTitle = to.meta.titleEN;
    if (i18n.global.locale.value === 'gr') {
        metaTitle = to.meta.titleGR;
    }
    document.title = metaTitle;
    next()
})

export default router
<template>
    <section class="cspd-pb cspd-pt" style="background-color: #f9f9f9">
        <div class="container">

            <div class="row">
                <div class="col-12">
                    <h2>{{ $t('home.videos.title') }}</h2>
                    <p class="mb-4">{{ $t('home.videos.content') }}</p>
                </div>
            </div><!-- end of row -->

            <div class="row">
                <div class="col-6 col-md-6 col-lg-3 text-center mb-3">
                    <a href="https://www.youtube.com/watch?v=fk4HhSKOg2Y" class="cspd-plain-link" target="_blank">
                        <img class="img-fluid" src="/images/video5.jpg" alt="">
                        <small>{{ $t('home.videos.video5') }}</small>
                    </a>
                </div>

                <div class="col-6 col-md-6 col-lg-3 text-center mb-3">
                    <a href="https://www.youtube.com/watch?v=CC3LSTXPgMg" class="cspd-plain-link" target="_blank">
                        <img class="img-fluid" src="/images/video6.jpg" alt="">
                        <small>{{ $t('home.videos.video6') }}</small>
                    </a>
                </div>

                <div class="col-6 col-md-6 col-lg-3 text-center mb-3">
                    <a href="https://www.youtube.com/watch?v=tZuIo9gOJiE" class="cspd-plain-link" target="_blank">
                        <img class="img-fluid" src="/images/video3.jpg" alt="">
                        <small>{{ $t('home.videos.video3') }}</small>
                    </a>
                </div>

                <div v-if="te('home.videos.video7')" class="col-6 col-md-6 col-lg-3 text-center mb-3">
                    <a href="https://www.youtube.com/watch?v=jyCJBv08vpQ" class="cspd-plain-link" target="_blank">
                        <img class="img-fluid" src="/images/video7.jpg" alt="">
                        <small>{{ $t('home.videos.video7') }}</small>
                    </a>
                </div>

                <div v-if="te('home.videos.video8')" class="col-6 col-md-6 col-lg-3 text-center mb-3">
                    <a href="https://www.youtube.com/watch?v=m0OFRYhA5Lc" class="cspd-plain-link" target="_blank">
                        <img class="img-fluid" src="/images/video8.jpg" alt="">
                        <small>{{ $t('home.videos.video8') }}</small>
                    </a>
                </div>

            </div><!-- end of row -->

            <div class="row">
                <div class="col-12 text-center">
                    <button @click="redirectToYTChannel" class="btn btn-primary cspd-button px-4" type="button">{{ $t('home.videos.button') }}</button>
                </div>
            </div><!-- end of row -->

        </div><!-- end of container -->
    </section>
</template>

<script setup>
import {useI18n} from "vue-i18n";

const {te} = useI18n();

const redirectToYTChannel = () => {
    window.open('https://www.youtube.com/channel/UCaw1d4pBdHU6b8wQgYmH2iA', '_blank');
}
</script>
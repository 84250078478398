<template>
    <section>
        <Intro/>
        <Parents/>
        <template v-if="te('home.campaigns')">
            <Campaigns/>
        </template>
        <Articles/>
        <Videos/>
        <Members/>
        <Sponsors/>
    </section>
</template>

<script setup>
import Members from "@/views/HomeComponents/Members.vue";
import Articles from "@/views/HomeComponents/Articles.vue"
import Videos from "@/views/HomeComponents/Videos.vue";
import Sponsors from "@/components/Sponsors.vue";
import Parents from "@/views/HomeComponents/Parents.vue";
import Intro from "@/views/HomeComponents/Intro.vue";
import {useI18n} from 'vue-i18n';
import Campaigns from "@/views/HomeComponents/Campaigns.vue";

const {te} = useI18n();
</script>

<template>
    <div class="container cspd-mb">
        <div class="row">

            <div class="col-md-6 px-5 d-flex align-items-center  cspd-card-blue cspd-card-mobile">
                <div>
                    <h1>{{ $t('registrations.title1') }}</h1>
                    <p class="cspd-lead">{{ $t('registrations.info') }}</p>

                </div>
            </div>

            <div class="col-md-6 g-0">
                <img class="img-fluid" src="/images/registrations-title.jpg" alt="dentist with child">
            </div>

        </div>
    </div>
</template>

<template>
    <div class="container cspd-mb">

        <div class="row">
            <div class="col">
                <h2>{{ $t('members.honoraryMembers.title') }}</h2>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 mb-4">
                <p class="cspd-lead">{{ $t('members.honoraryMembers.member1Title') }}</p>
                <p>{{ $t('members.honoraryMembers.member1Intro') }}</p>

                <button v-if="!isMember1ContentVisible" @click="isMember1ContentVisible = true" class="btn btn-primary cspd-button px-4" type="button">{{ $t('members.honoraryMembers.buttonMore') }}</button>
                <div v-if="isMember1ContentVisible" v-html="$t('members.honoraryMembers.member1Content')"></div>
                <button v-if="isMember1ContentVisible" @click="isMember1ContentVisible = false" class="btn btn-primary cspd-button px-4" type="button">{{ $t('members.honoraryMembers.buttonLess') }}</button>
            </div>

            <div class="col-md-6 mb-4">
                <p class="cspd-lead">{{ $t('members.honoraryMembers.member2Title') }}</p>
                <p>{{ $t('members.honoraryMembers.member2Intro') }}</p>

                <button v-if="!isMember2ContentVisible" @click="isMember2ContentVisible = true" class="btn btn-primary cspd-button px-4" type="button">{{ $t('members.honoraryMembers.buttonMore') }}</button>
                <div v-if="isMember2ContentVisible" v-html="$t('members.honoraryMembers.member2Content')"></div>
                <button v-if="isMember2ContentVisible" @click="isMember2ContentVisible = false" class="btn btn-primary cspd-button px-4" type="button">{{ $t('members.honoraryMembers.buttonLess') }}</button>
            </div>
        </div>

    </div>
</template>

<script setup>
import {ref} from "vue";

const isMember1ContentVisible = ref(false);
const isMember2ContentVisible = ref(false);
</script>

<template>
    <section class="pt-4 pb-2">
        <div class="container">
            <div class="row">
                <div class="col d-flex">
                    <small class="me-3">{{ $t('sponsors.title') }}</small>
                    <img src="/images/elgydium.jpg" alt="Elgydium" width="110" class="me-3">
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>

</script>

<style scoped>

</style>
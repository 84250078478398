<template>
    <div class="container cspd-mt cspd-mb">

        <div class="row">
            <div class="col-md-12">
                <h2>{{ $t('home.articles.title') }}</h2>
                <p class="mb-4">{{ $t('home.articles.content') }}</p>
            </div>
        </div><!-- end of row -->

        <div class="row">

            <div class="d-flex justify-content-center align-items-center col-12 col-md-12 col-lg-4 py-4" style="background-color:#1C988A;">
                <a href="/articles/trauma_final.pdf" target="_blank">
                    <img class="img-fluid" src="/images/save-a-tooth.png" alt="save a tooth">
                </a>
            </div>

            <div class="col-12 col-md-12 col-lg-8 px-lg-5 py-4">
                <p v-if="te('home.articles.article1')"><i style="color: #07A0E1;" class="bi bi-arrow-right"></i> {{ $t('home.articles.article1') }}
                    <a :href="$t('home.articles.article1Url')" target="_blank" class="cspd-plain-link">{{ $t('general.readArticle') }} <i class="bi bi-box-arrow-up-right"></i></a>
                </p>
                <p v-if="te('home.articles.article2')"><i style="color: #07A0E1;" class="bi bi-arrow-right"></i> {{ $t('home.articles.article2') }}
                    <a :href="$t('home.articles.article2Url')" target="_blank" class="cspd-plain-link">{{ $t('general.readArticle') }} <i class="bi bi-box-arrow-up-right"></i></a>
                </p>
                <p v-if="te('home.articles.article3')"><i style="color: #07A0E1;" class="bi bi-arrow-right"></i> {{ $t('home.articles.article3') }}
                    <a :href="$t('home.articles.article3Url')" target="_blank" class="cspd-plain-link">{{ $t('general.readArticle') }} <i class="bi bi-box-arrow-up-right"></i></a>
                </p>
                <p v-if="te('home.articles.article4')"><i style="color: #07A0E1;" class="bi bi-arrow-right"></i> {{ $t('home.articles.article4') }}
                    <a :href="$t('home.articles.article4Url')" target="_blank" class="cspd-plain-link">{{ $t('general.readArticle') }} <i class="bi bi-box-arrow-up-right"></i></a>
                </p>
                <p v-if="te('home.articles.article5')"><i style="color: #07A0E1;" class="bi bi-arrow-right"></i> {{ $t('home.articles.article5') }}
                    <a :href="$t('home.articles.article5Url')" target="_blank" class="cspd-plain-link">{{ $t('general.readArticle') }} <i class="bi bi-box-arrow-up-right"></i></a>
                </p>
                <p v-if="te('home.articles.article6')"><i style="color: #07A0E1;" class="bi bi-arrow-right"></i> {{ $t('home.articles.article6') }}
                    <a :href="$t('home.articles.article6Url')" target="_blank" class="cspd-plain-link">{{ $t('general.readArticle') }} <i class="bi bi-box-arrow-up-right"></i></a>
                </p>
                <p v-if="te('home.articles.article7')"><i style="color: #07A0E1;" class="bi bi-arrow-right"></i> {{ $t('home.articles.article7') }}
                    <a :href="$t('home.articles.article7Url')" target="_blank" class="cspd-plain-link">{{ $t('general.readArticle') }} <i class="bi bi-box-arrow-up-right"></i></a>
                </p>

                <router-link :to="{ name: 'articles' }">
                    <button class="btn btn-primary cspd-button px-4" type="button">{{ $t('home.articles.button') }}</button>
                </router-link>
            </div><!-- end of column -->
        </div><!-- end of row -->

    </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n';

const {te} = useI18n();
</script>
